import React, { useEffect, useState } from 'react'
import { PlayerStyle } from './Player.style'
import { useMediaQuery } from 'react-responsive'
import { NavLink, useParams } from 'react-router-dom';
function Player() {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const [data, setData] = useState()
  const { id } = useParams()
  useEffect(() => {
    const url = `https://api.hulijorden.dk/player/${id}`;
    
        fetch(url)
          .then(response => response.json())
          .then(data => {
             console.log(data);
            setData(data)
          
          })
          .catch(error => {
            console.error(error);
          });
        }, [id])
        
  return (
    <PlayerStyle>
                      { data ? <>
                            <section className='playerAvatar'>
                             <img src={`${data?.skin}`} alt="" /> 
                            </section>
                            <section className='playerInfo'>
                              <article className='Art1'>
                               <h1>{data?.name} <p>Sidst set: {new Intl.DateTimeFormat('en-GB', {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
}).format(new Date(Number(data?.last_seen)))}</p></h1> 
                               <h3>{data?.exp_lvl}<span>lvl</span></h3> 
                              </article>

                              <article className='Art2'>
                               <h2>Generelt</h2> 
                               <p>EXP: {data?.total_exp}</p> 
                               <p>Penge: {(data?.bal).toFixed(0)}$</p>
                              </article>

                              <article className='Art3'>
                                <div>
                                  <h2>Jobs</h2>
                                  <p>Woodcutter</p>
                                  <p>Explorer</p>
                                  <p>Farmer</p>
                                </div>
                                <div>
                                  <h2>Achivements</h2>
                                    <article>
                                    <div>
                                    <p>Stone!</p>
                                    <p>Stone!</p>
                                  </div>
                                       <div>
                                    <p>10 minutter siden</p>
                                    <p>10 minutter siden</p>
                                  </div>
                                    </article>

                                </div>
                              </article>

                              <article className='Art4'>
                                <div>
                                 <h2>Type</h2> 
                                 <p>Warn</p>
                                 <p>Ban</p>
                                </div>
                                
                                <div>
                                 <h2>Grund</h2> 
                               <p>Spreoget!</p>  
                               <p>Spreoget!</p> 
                                </div>

                                <div>
                                 <h2>Længde</h2> 
                                 <p>N/A</p>
                                 <p>1d</p>
                                </div>

                                <div>
                                  <h2>Givet af</h2>
                                  <p>Nice_kode4</p>
                                  <p>Nice_kode4</p>
                                  
                                </div>
                                </article>
                              
                            </section>

                            
                        
    
    </> : <h3>Ingen spillere med det navn</h3>}
                     
    </PlayerStyle>
  )
}

export default Player