import React, { useEffect, useState } from 'react'
import { FrontpageStyle } from './Frontpage.style'
import { useMediaQuery } from 'react-responsive'
import { NavLink } from 'react-router-dom';
function Frontpage() {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const [data, setData] = useState()
  useEffect(() => {
    const url = "https://api.hulijorden.dk/player";
    
        fetch(url)
          .then(response => response.json())
          .then(data => {
             console.log(data);
            setData(data)
          
          })
          .catch(error => {
            console.error(error);
          });
        }, [])
  return (
    <FrontpageStyle>

                        <section className='moneySec'>
                        <h2>Rigeste spillere</h2>
                     <ol>   {
                          
                          data?.[0].money?.map((player,index) => {
                            
                            
                            return(
                            
                              index < 6 ? <li>
                                                              <div className='numDiv'>
                                  <p>{index+1}</p>
                                </div>
                                <div>
                                 <NavLink to={`/players/${player.player_name}`}><img src={`https://mineskin.eu/helm/${player.player_name}/100.png`} alt="" />{player.player_name}</NavLink> <h5>{(player.money).toLocaleString()}$</h5>
                                </div>
                                <div className='onlineDiv'>
                                  {player.sync_complete === "false" ? "🟢" : "🔴"}
                                </div>
                                
                                </li> : null
                            
                          )})
                       } </ol>
                        </section>
                        
                        <section className='ExpSec'>
                        <h2>Mest erfarne spillere</h2>
                     <ol>   {
                          
                          data?.[0].exp?.map((player,index) => {
                            
                            
                            return(
                            
                              index < 6 ? <li>
                                <div className='numDiv'>
                                  <p>{index+1}</p>
                                </div>
                                <div>
                                                                  <NavLink to={`/players/${player.player_name}`}><img src={`https://mineskin.eu/helm/${player.player_name}/100.png`} alt="" />{player.player_name}</NavLink> <h5>{(player.total_exp).toLocaleString()} Exp</h5>
                                </div>
                                <div className='onlineDiv'>
                                  {player.sync_complete === "false" ? "🟢" : "🔴"}
                                </div>
                                
                                </li> : null
                            
                          )})
                       } </ol>
                        </section>
                        
                     
    </FrontpageStyle>
  )
}

export default Frontpage