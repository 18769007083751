import React, { useState } from 'react'
import { HeaderStyle } from './Header.style'
import logo from '../../assets/Whitelogo.png'
import { NavLink, useNavigate } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive';

function Header() {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const navigate = useNavigate()
  const [input, setInput] = useState("")
  return (
    <HeaderStyle>

     <img src={logo} alt="Logo"/>

<nav>
  <form action="">
    <input onChange={(e) => setInput(e.target.value)} placeholder='Søg bruger' type="text" />
    <button onClick={() => navigate(`/players/${input}`)}>Søg</button>
  </form>
    <ul>
      <li><NavLink to={'/'}>Forside</NavLink></li>
      <li><NavLink to={'https://hulijorden.dk/'}>↳ hulijorden.dk</NavLink></li>
    </ul>
</nav>
    </HeaderStyle>
  )
}

export default Header
