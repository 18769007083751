import styled from "styled-components";

export const PlayerStyle = styled.section`
display: grid;
height: 100vh;
grid-template-columns: 20% 80%;
	padding: 10px;
	margin-left: 2vw;
	margin-bottom: 2vh;
text-align: center;
font-size: 25px;
	img{
		height: 50vh;
		padding: 2vh 0vw;
	}

	.Art1{
		background-color: white;
		border-radius: 10px;
		display: flex;
		justify-content: space-between;
		padding: 1vh 2vw;
		h1{
			font-size: 33px;
			text-align: start;
			p{
				font-size: 20px;
			}
		}
	}

	.Art2{
		margin-top: 2vh;
		background-color: white;
		border-radius: 10px;
		padding: 1vh 2vw;
		h2{
			text-align: left;
		}
		p{
			text-align: left;
		}
	}

	.Art3{
		display: grid;
		grid-template-columns: 20% 80%;

		div{

			margin-top: 2vh;
		background-color: white;
		border-radius: 10px;
		padding: 1vh 2vw;
		}
		div:first-of-type{
			margin-right: 1vw;
		}
article{
	display: grid;
		grid-template-columns: 1fr 1fr;
		text-align: left;
		div{
			border-right: solid 1px black;
			border-radius: 0;
			margin-left: 0;
			padding-left: 0;
		}
}
		h2{
			text-align: left;
		}
	}

	.Art4{
		margin-top: 2vh;
		background-color: white;
		border-radius: 10px;
		padding: 1vh 2vw;
		display: grid;
		grid-template-columns: auto auto auto auto;
		div{
			border-right: solid 1px black;
			
			p{
				text-align: left;
				border-: solid 1px black;
			}
		}
		h2{
			text-align: left;
		}
	}
	h3{
		font-size: 35px;
		margin-top: 2vh;
		span{
			margin-left: 0;
			font-size: 20px;
		}
	}
	@media screen and (min-width: 768px) {
		margin: 2vh 0vw;
	border-radius: 10px;
	padding: 10px;
	margin-right: 10px;
	}
`