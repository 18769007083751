import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
export const HeaderStyle = styled.header`
height: 7vh;
background-color: green;
border-radius: 10px;
display: flex;
img{
    margin: 1vh 1vw;
}
nav{
    width: 100%;
    display: flex;
    padding-right: 2vw;
    form{
        display: flex;
        padding: 1.5vh 1vw;
        input{
            width: 15vw;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            border: none;
            padding: 1vh 1vw;
        }
        button{
            padding: 1vh 2vw;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            border: none;
        }
    }
    ul{
        width: 100%;
        list-style: none;
        display: flex;
        justify-content: end;
        margin-top: 2vh;
        overflow-x: auto;
       li{
        margin-left: 1vw;
            a{
        text-align: end;
        padding: 1h 1vw;
        text-decoration: none;
        color: white;
        font-size: 20px;
    }
       } 
    }

}
`