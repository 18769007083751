
import { useMediaQuery } from 'react-responsive';
import Approuter from './Approuter/Approuter';
import Footer from './Components/Footer/Footer';
import Header from './Components/Header/Header';
import usePageTitle from './Components/PageWrapper/PageTitle';
import SidebarOne from './Components/SidebarOne/SidebarOne';
import SidebarTwo from './Components/SidebarTwo/SidebarTwo';
import { GlobalStyle } from './Global.style';
import image from './assets/minecraft.png'


function App() {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  usePageTitle()
  
  return (
    <div className="App" style={{ 
      backgroundImage:`url(${image})`, 
      padding: '1vh 1vw', 
      backgroundSize: 'cover',
      backgroundAttachment: 'fixed',
      backgroundRepeat: 'no-repeat',
      height: "100%",
      }}>
      <GlobalStyle image="./assets/minecraft.png" />
      <Header />
      <main style={{height: 'fit-content', display: 'grid', gridTemplateColumns: isMobile ? '100%' : '100%', gap: '1vw'}}>
          <Approuter />
          <section>
          </section>
      </main>
      <Footer /> 
    </div>
  );
}

export default App;
