import styled from "styled-components";

export const FrontpageStyle = styled.section`
	padding: 10px;
	margin-left: 2vw;
	margin-bottom: 2vh;
	display: grid;
	justify-content: center;

	h2{
		text-align: center;
	}
.moneySec{
	margin-right: 5vw;
}
ol{
	font-size: 20px;
	li{
		display: flex;
		justify-content: space-between;
		background-color: white;
		text-align: center;
		width: 75vw;
		padding: 2vh 2vw;
		margin: 2vh 0vw;
		border-radius: 10px;
		a{
		img{
			height: 3vh;
			margin-right: 1vw;
			display: inline;
			vertical-align: middle;
		
		}
			text-decoration: none;
			color: black;
			text-align: center;
			padding-bottom: 1vh;
			display: block;
			
		}
		div:first-of-type{
		width: 10%;
		}
		.numDiv{
			margin-top: 3%;
		}
		.onlineDiv{
			width: auto;
			display: flex;
			justify-content: end;
			color: red;
			margin-top: 3%;
			padding-left: 1%;
			text-align: left;
			
		}
	}
}

    h1 {
		color: green;
		font-size: 35px;
		text-align: center;
	}

	@media screen and (min-width: 768px) {
		padding: 10px;
	margin-left: 2vw;
	margin-bottom: 2vh;
	display: flex;
	justify-content: center;

	h2{
		text-align: center;
	}
.moneySec{
	margin-right: 5vw;
}
ol{
	font-size: 20px;
	li{
		display: flex;
		justify-content: space-between;
		background-color: white;
		text-align: center;
		width: 35vw;
		padding: 2vh 2vw;
		margin: 2vh 0vw;
		border-radius: 10px;
		a{
			text-decoration: none;
			color: black;
			text-align: center;
			padding-bottom: 1vh;
			display: block;
			
		}
		div:first-of-type{
		width: 10%;
		}
		.numDiv{
			margin-top: 3%;
		}
		.onlineDiv{
			width: auto;
			display: flex;
			justify-content: end;
			color: red;
			margin-top: 3%;
			padding-left: 1%;
			text-align: left;
			
		}
	}
}

    h1 {
		color: green;
		font-size: 35px;
		text-align: center;
	}
	}
`