import React from 'react'
import Frontpage from '../Pages/Frontpage/Frontpage'
import { Route, Routes } from 'react-router-dom'
import Player from '../Pages/Player/Player'

function Approuter() {
  return (
    <Routes>
        <Route index element={<Frontpage />}/>
        <Route path='/players/:id' element={<Player />}/>
    </Routes>
  )
}

export default Approuter